import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import App from './App'
import 'mapbox-gl/dist/mapbox-gl.css'
import {createBrowserRouter, Route, Router, RouterProvider, Routes} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
]);

ReactDOM.render(
    <React.StrictMode>
    <RouterProvider router={router} />
    </React.StrictMode>,
    document.getElementById('root')
)
