import React, {useState, useRef, useEffect} from 'react'
import Map, {Layer, Source} from 'react-map-gl'
import mapboxgl from 'mapbox-gl'
import bbox from '@turf/bbox'
import * as turf from '@turf/turf'
import {FaTimes} from 'react-icons/fa'

// Components
import Table from './components/table'
import LoadingSpinner from './components/loadingSpinner'
import Examples from './components/examples'
import toast, {Toaster} from "react-hot-toast"

// Utils
import {
    cleanupQuery,
    getCities,
    getZipcodes,
    getZipcodesMapboxFormatted,
} from './utils'

// Mapbox UI configuration
import {
    zipcodeFeatures,
    citiesFeatures,
    zipcodeLayerHigh,
    zipcodeLayerLow,
    citiesLayer,
} from './mapbox-ui-config'

import './css/App.css'
import {DiscordButton, GithubButton} from './Discord'
import {notify} from "./Toast";
import {useDebouncedCallback} from "use-debounce";
import {useSearchParams} from "react-router-dom";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// TODO: update this to public URL
let api_endpoint = 'https://ask-api.osrtc.org'

if (process.env.REACT_APP_HOST_ENV === 'dev') {
    api_endpoint = 'http://localhost:3001'
}

const SearchButton = (props) => {
    const {value, onSearchChange, onClear} = props
    return (
        <div className="flex rounded-md shadow-sm w-full md:max-w-lg">
            <div className="relative flex flex-grow items-stretch focus-within:z-10  ">
                {/*<input*/}
                {/*  type="email"*/}
                {/*  name="email"*/}
                {/*  id="email"*/}
                {/*  className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                {/*  placeholder="John Smith"*/}
                {/*/>*/}

                <input
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Ask anything about OSRTC"
                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={value}
                    onChange={onSearchChange}
                />
            </div>
            <button
                type="button"
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={onClear}
            >
                <FaTimes/>
            </button>
        </div>
    )
}

function App(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = useState('')
    const [sql, setSQL] = useState('')
    const [zipcodesFormatted, setZipcodesFormatted] = useState([])
    const [zipcodes, setZipcodes] = useState([])
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [statusCode, setStatusCode] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')
    const [cities, setCities] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [latlon, setLatlon] = useState([])

    useEffect(() => {
        document.title = query || 'OSRTC'
    }, [query])

    useEffect(() => {
        if (errorMessage !== '') {
            console.log(errorMessage);
            // notify(errorMessage)
        }
    }, [errorMessage])

    const queryParameters = new URLSearchParams(window.location.search)
    const urlSearch = queryParameters.get('s')

    const clearMapLayers = () => {
        setCities([])
        setZipcodes([])
        setZipcodesFormatted([])
    }

    const mapRef = useRef()

    const handleSearchChange = (event) => {
        const {value} = event.target
        setQuery(value)
        setTitle('')
    }

    const handleClearSearch = () => {
        setQuery('')
    }

    const fetchBackend = (natural_language_query) => {
        // Don't send a request if the query is empty!
        natural_language_query = natural_language_query.trim()
        if (!natural_language_query.length) return

        // Set the loading state
        setIsLoading(true)

        // clear previous layers
        clearMapLayers()

        // Sanitize the query
        natural_language_query = cleanupQuery(natural_language_query)

        // Set the options for the fetch request
        const options = {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: '{"natural_language_query":"' + natural_language_query + '"}',
        }

        let responseOuter = null
        // Send the request
        fetch(api_endpoint + '/api/text_to_sql', options)
            .then((response) => response.json())
            .then((response) => {
                // Set the loading state to false
                setIsLoading(false)

                // Handle errors
                if (!response || !response.sql_query || !response.result) {
                    setErrorMessage(
                        'Something went wrong. Please try again or try a different query'
                    )
                    return
                }

                // Set the state for SQL and Status Code
                setStatusCode(response.status)
                responseOuter = response
                setSQL(response.sql_query)

                console.log('Backend Response ==>', response)

                // Filter out lat and long columns
                let filteredColumns = response.result.column_names.filter(
                    (c) => c !== 'lat' && c !== 'long'
                )
                setColumns(filteredColumns)

                // Fit the order of columns and filter out lat and long row values
                let rows = response.result.results.map((value) => {
                    let row = []
                    // Find each of the filtered column value in the object and push it into the row
                    filteredColumns.map((c) => row.push(value[c]))
                    return row
                })
                setRows(rows)


                // Filter out lat and long columns
                let latlonArray=[]
                response?.result?.results?.map((item)=>{
                    let tempArr={};
                    Object.keys(item).forEach(function(key, index) {
                        if(key.includes('lat')){
                            tempArr['lat']=item[key]
                       }
                        if(key.includes('lon')){
                            tempArr['long']=item[key]
                        }
                        if(!key.includes('lat') && !key.includes('lon')){
                            tempArr["label"]=item[key]
                        }
                      })

                      latlonArray.push(tempArr)
                })
                if(latlonArray.length>0){
                mapRef.current.flyTo({
                    center: [
                        latlonArray[0].long,
                        latlonArray[0].lat,
                    ],
                    essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                })
            }
                setLatlon(latlonArray)

            })
            .catch((err) => {
                setIsLoading(false)
                setStatusCode(500)
                // setErrorMessage(err.message || err)
                console.error(err)
            })
    }

    const debouncedFetchBackend = useDebouncedCallback((query) => {
        fetchBackend(query)
    }, 100);

    useEffect(() => {
        const queryFromURL = searchParams.get('s');
        if (queryFromURL != query) {
            setQuery(urlSearch)
            debouncedFetchBackend(urlSearch)
        }
    }, [searchParams])

    const handleSearchClick = (event) => {
        setSearchParams(`?${new URLSearchParams({s: query})}`)
        setTitle(query)
        fetchBackend(query)
    }

    return (
        <div className="App">
            <link
                href="https://api.tiles.mapbox.com/mapbox-gl-js/v0.44.2/mapbox-gl.css"
                rel="stylesheet"
            />
            <div className="overflow-hidden rounded-lg bg-white shadow md:h-screen">
                <div className="px-4 py-5 sm:px-6">
                    <h1
                        className="text-4xl font-bold mb-6"
                        onClick={() => {
                            window.location.assign('/')
                            handleClearSearch()
                        }}
                        style={{cursor: 'pointer'}}
                    >
                        <img
                            src="osrtc_logo.png"
                            style={{margin: '0 auto', width: '120px'}}
                        />
                    </h1>
                    <Toaster/>
                    <div>
                        <form
                            autoComplete={'off'}
                            className="mt-1 flex justify-center"
                            onSubmit={(event) => {
                                event.preventDefault()
                                handleSearchClick(event)
                            }}
                        >
                            <SearchButton
                                value={query}
                                onSearchChange={handleSearchChange}
                                onClear={handleClearSearch}
                            />
                            {/*<input*/}
                            {/*  type="text"*/}
                            {/*  name="search"*/}
                            {/*  id="search"*/}
                            {/*  placeholder="Ask anything about US Demographics..."*/}
                            {/*  className="block w-full mr-2 rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm md:max-w-lg"*/}
                            {/*  value={query}*/}
                            {/*  onChange={handleSearchChange}*/}
                            {/*/>*/}
                            {/*{query && (*/}
                            {/*  <button*/}
                            {/*    className="right-20 text-gray-400 hover:text-gray-500 focus:outline-none"*/}
                            {/*    onClick={handleClearSearch}*/}
                            {/*  >*/}
                            {/*    <FaTimes />*/}
                            {/*  </button>*/}
                            {/*)}*/}
                            <button
                                type="submit"
                                className="text-white bg-blue-600 focus:ring-4 focus:ring-blue-300 focus:outline-none inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium shadow-sm hover:bg-blue-700 ml-3"
                            >
                                Search
                            </button>
                        </form>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 h-full sm:p-6 flex flex-col md:flex-row md:pb-[200px]">
                    <div
                        className="rounded-lg overflow-y-scroll max-h-[60vh] h-full md:h-full md:max-h-full bg-white shadow flex-grow-[0] w-full mr-8 mb-8">
                        {/*spinner*/}
                        <LoadingSpinner isLoading={isLoading}/>
                        {sql.length === 0 && !isLoading ? (
                            <Examples
                                setQuery={setQuery}
                                handleClick={fetchBackend}
                            />
                        ) : isLoading ? (
                            <> </>
                        ) : (
                            <>
                                <p class="my-2 font-medium"> {title} </p>
                                <div className="p-4">
                                    <pre
                                        align="left"
                                        className="bg-gray-100 rounded-md p-2 overflow-auto"
                                    >
                                        <code className="text-sm text-gray-800 language-sql">
                                            {/*{sql}*/}
                                        </code>
                                    </pre>
                                </div>
                                {/*{statusCode === 500 ? (*/}
                                {/*    <ErrorMessage errorMessage={errorMessage} />*/}
                                {/*) : (*/}
                                {/*    <></>*/}
                                {/*)}*/}
                                <Table columns={columns} values={rows}/>
                            </>
                        )}
                    </div>
                    <div className="overflow-hidden rounded-lg bg-white shadow flex-grow-[2] h-[70vh] md:h-full w-full">
                        <Map
                            ref={mapRef}
                            mapboxAccessToken="pk.eyJ1IjoiY2hhcnRyIiwiYSI6ImNsZjZ1dWIweTE4bHY0MG9jNjl2cHR3cWQifQ.EL4cBP-1-AC-s8cbFikMzQ"
                            style={{width: '100%', height: '100%'}}
                            mapStyle="mapbox://styles/mapbox/dark-v11"
                            initialViewState={{
                                longitude: 85.8245,
                                latitude: 20.2961,
                                zoom: 8,
                                bounds: [[81.8266, 17.7407], [88.0974, 22.8947]]
                            }}

                        >
                            <Source
                                id="zip-zoomed-out"
                                type="geojson"
                                data={{
                                    type: 'FeatureCollection',
                                    features: zipcodeFeatures(latlon),
                                }}
                            >
                                <Layer {...zipcodeLayerHigh} />
                            </Source>

                        </Map>
                        ;
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App

// /* DO NOT REMOVE

// Use this to find out what feature info is pulled for each zipcode from the vector tiles

//     map.current.on('mousemove', function (e) {
//       var features = map.current.queryRenderedFeatures(e.point, {
//           layers:  ['Zip', 'zips-kml']
//       });
//       if (features.length > 0) {
//         console.log("\n\nFEATURES => ", features)
//           if (features[0].layer.id == 'Zip') {
//             console.log("ZIP5", features[0].properties.ZIP5)
//           } else if (features[0].layer.id == 'zips-kml') {
//             console.log("ZIPS-KMLLLL", features[0].properties.Name)
//             console.log("ZIPS-KML", features[0].properties.Name.replace(/^\D+/g, '').split("<closeparen>")[0])
//             console.log("TYYPE>>>", typeof features[0].properties.Name.replace(/^\D+/g, ''));

//           } else {
//             console.log("ZCTAE10", features[0].properties.ZCTA5CE10)
//           }

//       } else {
//           // document.getElementById('pd').innerHTML = '<p>Hover over a state!</p>';
//       }
//   });

//   */

//   });
